<template>
  <div>
    <!-- banner -->
    <banner></banner>
    <div class="bannerBox" @click="goMeet" v-if="LOCALE == 'zh'">
      <div class="bannerContent">
        <div
          class="bannerInner"
          :style="{ 'background-image': 'url(' + bannerenMeetImg + ')' }"
        ></div>
      </div>
    </div>
    <div class="Offlinetour">
      <!-- 展位图 -->
      <cooperationMedia></cooperationMedia>
      <!-- <div class="floorplan">
        <el-image
          v-if="LOCALE == 'en'"
          style="width: 100%"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/floorplanen.png"
          fit="scale-down"
        ></el-image>
        <el-image
          v-else
          style="width: 100%"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/floorplanzh.png"
          fit="scale-down"
        ></el-image>
      </div> -->
      <!-- 赞助方案 -->
      <div class="approval">
        <div class="approval_title">
          {{ $t("sponsorlist") }}
        </div>
        <el-table
          :data="sponsorshipList"
          border
          style="width: 100%"
          :span-method="arraySpanMethod"
        >
          <el-table-column :label="$t('sponsoredproduct')" align="center">
            <template slot-scope="scope">
              {{ scope.row.titleEn | priorFormat(scope.row.titleZh, LOCALE) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('quantity')" align="center">
            <template slot-scope="scope">
              {{
                scope.row.quantityEn | priorFormat(scope.row.quantityZh, LOCALE)
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('sponsorCompany')" align="center">
            <template slot-scope="scope">
              <div v-if="LOCALE == 'en'" v-html="scope.row.componyEn"></div>
              <div v-if="LOCALE == 'zh'" v-html="scope.row.componyZh"></div>
              <!-- {{scope.row.componyEn|priorFormat(scope.row.componyZh,LOCALE)}} -->
            </template>
          </el-table-column>
          <el-table-column :label="$t('nationality')" align="center">
            <template slot-scope="scope">
              {{
                scope.row.nationalityEn
                  | priorFormat(scope.row.nationalityZh, LOCALE)
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('status')" align="center">
            <template slot-scope="scope">
              {{ scope.row.statusEn | priorFormat(scope.row.statusZh, LOCALE) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 周边 -->
      <div
        class="around"
        :class="{ zh_top: LOCALE == 'zh', en_top: LOCALE == 'en' }"
      >
        <div>
          <img :src="around" alt="" />
        </div>
        <div class="msg">
          <div class="title">{{ periphery.title }}</div>
          <div class="box">
            <div class="item" v-for="(item, i) in periphery.item" :key="i">
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <div class="address">
        <el-card
          class="msg"
          :class="{ zh_bottom: LOCALE == 'zh', en_bottom: LOCALE == 'en' }"
          style="
            background-size: 438px 403px;
            background-repeat: no-repeat;
            background-position: bottom right;
          "
        >
          <div class="top">
            <div class="left">
              <!-- 地理位置 -->
              <div
                class="title"
                :style="{ backgroundImage: 'url(' + orange + ')' }"
                style="background-size: cover; width: 191px"
              >
                <span>{{ address.title }}</span>
              </div>
              <div class="msg_b test_style_s">{{ address.add }}</div>
            </div>
            <div class="right">
              <!-- 公共交通 -->
              <div
                class="title"
                :style="{ backgroundImage: 'url(' + green + ')' }"
                style="background-size: cover; width: 191px"
              >
                <span>{{ car.bus }}</span>
              </div>
              <div class="">
                <div
                  style="padding-bottom: 10px"
                  class="test_style"
                  v-for="(item, i) in bus"
                  :key="i"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <!-- 出租车 左 -->
            <div class="left">
              <div
                class="title"
                :style="{ backgroundImage: 'url(' + pink + ')' }"
                style="background-size: cover; width: 191px"
              >
                <span>{{ car.taxi }}</span>
              </div>
              <div v-if="LOCALE == 'zh'">
                <div
                  v-for="(item, i) in taxi_left"
                  :key="i"
                  style="padding-bottom: 20px; width: 460px"
                  class="test_style"
                >
                  <p class="test_style_s">{{ item.from }}</p>
                  <span class="test_style_s">{{ item.way }}</span>
                </div>
              </div>
              <div v-if="LOCALE == 'en'">
                <div
                  v-for="(item, i) in taxi_left"
                  :key="i"
                  style="padding-bottom: 20px; width: 460px"
                  class="test_style"
                >
                  <p class="test_style_s">{{ item.from }}{{ item.way }}</p>
                </div>
              </div>
            </div>
            <!-- 出租车 右 -->
            <div class="right">
              <!-- 占位 -->
              <div class="title">
                <span></span>
              </div>
              <div>
                <div
                  v-for="(item, i) in taxi_right"
                  :key="i"
                  style="padding-bottom: 20px"
                >
                  <p class="test_style_s">{{ item }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <!-- 地图 -->
      <div class="map">
        <mapPage :longitude="longitude" :dimension="dimension"></mapPage>
      </div>
      <!-- 展馆图电子版 -->
      <!-- <div class="tu">
                展馆图电子版
            </div> -->
    </div>
  </div>
</template>
<script>
import banner from "~exp/components/common/banner";
import mapPage from "~/baseComponents/map";
import cooperationMedia from "~exp/components/home/cooperationMedia";

export default {
  components: { banner, mapPage, cooperationMedia },
  data() {
    return {
      longitude: 121.398757,
      dimension: 31.227135,
      bannerenMeetImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/home/meetBanner.png",
      pink:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/aboutExp/pink.png",
      orange:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/aboutExp/orange.png",
      green:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/aboutExp/green.png",
      aircraft:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/aboutExp/aircraft.png",
      around:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/aboutExp/around.png",
      address: {
        title: this.$t("Offlinetour_address_tit"),
        add: this.$t("Offlinetour_address_dsc"),
      },
      car: {
        taxi: this.$t("Offlinetour_Taxi"),
        bus: this.$t("Offlinetour_Bus"),
      },
      taxi_left: [
        {
          from: this.$t("Offlinetour_taxi_left_from1"),
          way: this.$t("Offlinetour_taxi_left_way1"),
        },
        {
          from: this.$t("Offlinetour_taxi_left_from2"),
          way: this.$t("Offlinetour_taxi_left_way2"),
        },
      ],
      taxi_right: [
        this.$t("Offlinetour_taxi_right1"),
        this.$t("Offlinetour_taxi_right2"),
        this.$t("Offlinetour_taxi_right3"),
      ],
      bus: [
        this.$t("Offlinetour_bus1"),
        this.$t("Offlinetour_bus2"),
        this.$t("Offlinetour_bus3"),
        this.$t("Offlinetour_bus4"),
      ],
      //周边配套
      periphery: {
        title: this.$t("Offlinetour_periphery_title"),
        item: [
          this.$t("Offlinetour_periphery_item1"),
          this.$t("Offlinetour_periphery_item2"),
          this.$t("Offlinetour_periphery_item3"),
          this.$t("Offlinetour_periphery_item4"),
        ],
      },
      exhibitorsList: [],
      sponsorshipList: [
        {
          titleEn: "Special Booth",
          titleZh: "特装展位光地",
          quantityEn: "limited to 4",
          quantityZh: "仅限4家",
          componyEn: "YQNLINK",
          componyZh: "运去哪",
          nationalityEn: "China",
          nationalityZh: "中国",
          statusEn: "Sold out",
          statusZh: "售罄",
        },
        {
          componyEn: "MPA",
          componyZh: "MPA",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          componyEn: "Suzhou Yun Lgistics Technology,Co.Ltd.",
          componyZh: "苏州海管家物流科技有限公司",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          componyEn:
            "Tianjin Consol International Group Co.,Ltd.<br/>Shipco Transport （Shanghai）Ltd.",
          componyZh:
            "天津滨海松昌国际物流（集团）有限公司<br/>顺科国际货运代理（上海）有限公司 ",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          titleEn: "1v1 window video ad",
          titleZh: "1v1窗口15秒广告",
          quantityEn: "limited to 1",
          quantityZh: "独家",
          componyEn: "MPA",
          componyZh: "MPA",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          titleEn: "Sponsor for Welcome dinner",
          titleZh: "晚宴冠名",
          quantityEn: "limited to 1",
          quantityZh: "独家",
          componyEn: "Guangdong Morelink Information Technology Co.,Ltd",
          componyZh: "广东无限链接信息科技有限公司",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          titleEn: "Super-Large Banner",
          titleZh: "巨幅广告",
          quantityEn: "limited to 2",
          quantityZh: "仅限2家",
          componyEn: "MPA",
          componyZh: "MPA",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          componyEn: "YQNLINK",
          componyZh: "运去哪",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          titleEn: "T Board Banners in Free talk area",
          titleZh: "自由洽谈区-T形板",
          quantityEn: "limited to 1",
          quantityZh: "独家",
          componyEn: "MPA",
          componyZh: "MPA",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          titleEn: "Badge",
          titleZh: "胸卡",
          quantityEn: "limited to 1",
          quantityZh: "独家",
          componyEn:
            "Tianjin Qiancheng International Forwarding Agency Co.,Ltd.",
          componyZh: "天津乾程国际货运代理有限公司",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          titleEn: "Lanyard",
          titleZh: "吊绳",
          quantityEn: "limited to 1",
          quantityZh: "独家",
          componyEn: "MPA",
          componyZh: "MPA",
          nationalityEn: "China",
          nationalityZh: "中国",
        },
        {
          titleEn: "Drinking",
          titleZh: "矿泉水",
          quantityEn: "limited to 1",
          quantityZh: "独家",
          componyEn: "Bestway Logistics Inc",
          componyZh: "Bestway Logistics Inc",
          nationalityEn: "America",
          nationalityZh: "美国",
        },
        {
          titleEn: "1V1 Video Page Banner",
          titleZh: "1V1视频页Banner",
          quantityEn: "limited to 2",
          quantityZh: "仅限2家",
          componyEn: "MCC World International Pty Ltd",
          componyZh: "MCC World International Pty Ltd",
          nationalityEn: "Australia",
          nationalityZh: "澳大利亚",
          statusEn: "Only 1 left",
          statusZh: "仅余一家",
        },
        {
          titleEn: "Colored advertising on the conference brochure",
          titleZh: "会刊彩插",
          quantityEn: "limited to 6",
          quantityZh: "仅限6家",
          componyEn: "Sichuan Zhongshan Logistics Co., Ltd",
          componyZh: "四川中善物流有限公司",
          nationalityEn: "China",
          nationalityZh: "中国",
          statusEn: "Sold out(The first page)",
          statusZh: "封一售罄",
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let params = {};
      params.nopage = 1;
      params.meeting_id = this.MEETING_ID;
      params.apply_type = "2";
      let data = await this.$store.dispatch(
        "booth/getExpoExhibitorList",
        params
      );
      console.log(data);
      if (data.success) {
        this.exhibitorsList = data.data;
      }
    },
    indexMethod(index) {
      return index + 1;
    },
    goMeet() {
      let url =
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/MeetingGuide.pdf";
      window.open(url);
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else if (rowIndex === 1 || rowIndex === 2 || rowIndex === 3) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        } else if (rowIndex === 4 || rowIndex === 5) {
          return {
            rowspan: 1,
            colspan: 1,
          };
        } else if (rowIndex === 6) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else if (rowIndex === 7) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 1) {
        if (rowIndex === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else if (rowIndex === 1 || rowIndex === 2 || rowIndex === 3) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        } else if (rowIndex === 4 || rowIndex === 5) {
          return {
            rowspan: 1,
            colspan: 1,
          };
        } else if (rowIndex === 6) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else if (rowIndex === 7) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
      if (columnIndex === 4) {
        if (rowIndex === 0) {
          return {
            rowspan: 12,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bannerBox {
  width: 100%;
  padding-top: 7%;
  position: relative;
  cursor: pointer;

  .bannerContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    .bannerInner {
      height: 100%;
      background-size: 100% 100%;
      position: relative;
      transition: all 0.5s;
    }
    .bannerInner:hover {
      transform: scale(1.1);
    }
  }
}
.zh_bottom {
  bottom: 0px;
}
.en_bottom {
  bottom: -170px;
}
.zh_top {
  margin-top: 60px;
}
.en_top {
  margin-top: 230px;
}

.Offlinetour {
  width: 1300px;
  margin: auto;
  padding: 50px 0;
  .address {
    height: 554px;
    .msg {
      width: 100%;
      background: #fff;
      .top,
      .bottom {
        display: flex;
        .test_style {
          font-size: 16px;
          font-weight: 400;
          color: #1f292e;
        }
        .test_style_s {
          font-size: 16px;
          font-weight: 400;
          color: #1f292e;
          line-height: 30px;
        }
        .left {
          width: 616px;
          padding-left: 91px;
          margin-bottom: 40px;
          .msg_b {
            width: 460px;
          }
        }
        .right {
          width: 594px;
        }
        .title {
          height: 38px;
          padding-left: 15px;
          margin-bottom: 24px;
          span {
            font-size: 18px;
            font-weight: 800;
            line-height: 38px;
            color: #1f292e;
          }
        }
      }
    }
  }
  .map {
    height: 415px;
  }
  .around {
    padding-bottom: 60px;
    display: flex;
    .msg {
      width: 620px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding-left: 70px;
      .title {
        border-left: 4px solid #027fff;
        padding-left: 15px;
        font-size: 18px;
        font-weight: 800;
        color: #1f292e;
        line-height: 25px;
        margin-bottom: 15px;
      }
      .box {
        width: 415px;
        .item {
          padding-bottom: 20px;
          font-size: 16px;
          font-weight: 400;
          color: #1f292e;
          line-height: 22px;
        }
      }
    }
  }
  .tu {
    margin-top: 87px;
    margin-bottom: 70px;
    height: 555px;
    background-color: #d8d8d8;
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    color: #1f292e;
    line-height: 555px;
  }
  .floorplan {
    width: 100%;
    margin-bottom: 30px;
  }
  .join_meeting_company {
    margin-bottom: 50px;
  }
}
.join_title,
.approval_title {
  font-size: 24px;
  font-weight: 800;
  color: #333333;
  text-align: center;
  margin-bottom: 25px;
}
</style>
