<template>
  <div class="content_area containerBox">
    <div class="title">{{$t('Pavilionlayout')}}</div>
    <div class="list">
      <al-image class="img" :src="img" fit="fill"></al-image>
    </div>
  </div>
</template>

<script>
  export default {
    name: "cooperationMedia",
    data() {
      return {
        img:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/fuda/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210417142425.png"
      }
    }
  }
</script>

<style scoped lang="less">
  .containerBox {
    padding-bottom: 44px;
    .title {
      color: #333333;
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 24px;
    }
    .list {
      box-sizing: border-box;
      .img{
        width: 100%;
      }
    }
  }
</style>